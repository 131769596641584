body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #001823;
  border-radius: 10px;
}

@media (max-width: 480px) {
  ::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  background-color: #001823;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002e45;
  border-radius: 10px;
  height: 8px;
  width: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #005279;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(100%) sepia(100%) saturate(10000%) hue-rotate(180deg);
}

/*  */
.css-1riiifc-MuiTypography-root {
  color: #fff;
}

/*  */
.error-mess {
  color: red;
}

.is-invalid {
  border: 1px solid red;
}

.btn-logout svg {
  fill: #fff !important;
}

.btn-logout:hover svg {
  fill: #fc5719 !important;
}

/*  */
.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg 50 50);
}

.svg-pi-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.svg-pi-label__progress {
  font-weight: 600;
  font-size: 16px;
  line-height: 24.2px;
}

.w-100 {
  min-width: 100%;
}



.white-bg {
  background-color: transparent !important;
}

.white-bg .MuiInputBase-colorPrimary {
  background-color: white !important;
  border: 1px solid #dedede;
  color: #000 !important;
}

.white-bg .MuiCardContent-root {
  padding: 12px;
}

.white-bg .white-bg-container .success-container {
  max-width: 480px !important;
}

.white-bg .MuiMenu-list, .white-bg .MuiAutocomplete-popper 
.MuiAutocomplete-listbox, 
.white-bg .MuiMenu-list, .white-bg .MuiAutocomplete-popper .MuiAutocomplete-option {
  background: #fff;
  color: #000 !important;
}



.white-bg .white-bg-container .MuiDialogContent-root,
.white-bg .white-bg-container .success-container {
  background-color: #fff;
}

.white-bg .white-bg-container .MuiCardContent-root, .white-bg .white-bg-container .otp-input input {
  background-color: #d4d4d4 !important;
  color: #000 !important;
}

.white-bg .white-bg-container .MuiDialogContent-root .MuiTypography-root,
.white-bg .white-bg-container .MuiDialogContent-root .MuiTypography-root span,
.white-bg .white-bg-container .MuiDialogContent-root .MuiPaper-root .MuiCardContent-root,
.white-bg .white-bg-container .success-container .MuiTypography-root {
  color: #000;

}

.white-bg .white-bg-container .MuiButton-containedPrimary:hover {
  border: 1px solid #FC5719;
}

.account-information-label {
  margin-bottom: 0.4rem;
  display: block;
}